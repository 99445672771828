import React, { useEffect, useContext, useState } from "react";
import styled from "styled-components";
import { Context } from "../context/context";
import {
  Selection,
  Edge,
  ContextProps,
  ImageRect,
} from "../@interfaces/interfaces";
import { adjustToScale } from "../label_processing/label_processing";

const Wrapper = styled.div`
  position: absolute;
  pointer-events: none;
  background-color: transparent;
  svg {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background-color: transparent;
  }
`;

const Canvas = ({
  rect,
  scale,
  index,
  strokeWidth,
}: {
  rect: ImageRect;
  scale: number;
  index: number;
  strokeWidth: number;
}) => {
  const { selections } = useContext(Context) as ContextProps;
  const [currentSelections, setCurrentSelections] = useState<Selection[] | []>(
    []
  );

  useEffect(() => {
    if (scale !== Infinity) {
      const adjustedSelections = adjustToScale(
        selections.filter((el: Selection) => el.imageId === index),
        scale
      );
      setCurrentSelections(adjustedSelections);
    }
  }, [setCurrentSelections, selections, index, scale]);

  return (
    <Wrapper
      style={{
        top: Math.round(rect.top),
        left: Math.round(rect.left),
        width: `${rect.width}px`,
        height: `${rect.height}px`,
      }}
    >
      {currentSelections &&
        currentSelections.map((item: Selection, i: number) => {
          if (item.selection.edges.length !== 0 ) {
              return <svg id={`${i}`} key={i}>
                <g key={i}>
                  {item.selection.edges.map((edge: Edge, i: number) => {
                    return (
                      <line
                        key={i}
                        x1={edge[0][0]}
                        y1={edge[0][1]}
                        x2={edge[1][0]}
                        y2={edge[1][1]}
                        stroke="red"
                        strokeWidth={strokeWidth}
                      />
                    );
                  })}
                </g>
              </svg>
          }
        })}
    </Wrapper>
  );
};

export default Canvas;
