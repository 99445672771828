import React, { useState, useRef, useContext, useEffect } from "react";
import SelectBox from "./SelectBox";
import { Context } from "../context/context";
import Canvas from "./Canvas";
import {
  getAllCoordsOfRectangle,
  transformLasso,
} from "../label_processing/label_processing";
import {
  TransformWrapper,
  TransformComponent,
  ReactZoomPanPinchRef,
} from "react-zoom-pan-pinch";
import { adjustToScale } from "../label_processing/label_processing";
import { useAuth } from "../context/auth";
import { authContextProps } from "../@interfaces/authContext";
import { ContextProps } from "../@interfaces/interfaces";
import { sendMarkedSequence } from "../networking/sequenceControllerNetwork";
import FullscreenControlPanel from "./FullscreenControlPanel";
import styled from "styled-components";

import { SequenceDataProps } from "../@interfaces/interfaces";
import { Edge } from "../@interfaces/interfaces";

const Wrapper = styled.div`
  position: absolute;
  display: flex;
  justify-content: center;
  flex-direction: row;
  align-items: center;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: black;

  .main-image-fullscreen {
    object-fit: contain;
    width: 100%;
    max-height: 100vh;
  }

  .selection-box {
    position: absolute;
    cursor: crosshair;
    z-index: 10;
    background-color: transparent;
  }
`;

interface FullscreenProps {
  seq_name: string | undefined;
  frame_name: string | undefined;
  index: number;
  total_frames: number;
  toggle_screen: () => void;
  shiftPress: boolean;
  isLoading: boolean;
  sequenceData: SequenceDataProps;
}

function Fullscreen({
  seq_name,
  frame_name,
  index,
  total_frames,
  toggle_screen,
  shiftPress,
  isLoading,
  sequenceData,
}: FullscreenProps) {
  let og_scale = 1;
  const [startCoords, setStartCoords] = useState({ x: 0, y: 0 });
  const [endCoords, setEndCoords] = useState({ x: 0, y: 0 });
  const [isDrawing, setIsDrawing] = useState({ active: false, type: "draw" });

  const [lassoPoints, setLassoPoints] = useState<{ x: number; y: number }[]>(
    []
  );

  const [zoomOffScale, setZoomOffScale] = useState({ x: 0, y: 0, scale: 1 });
  const wrapperRef = useRef<ReactZoomPanPinchRef | null>(null);
  const imageRef = useRef<HTMLImageElement>(null);
  const mainWrapperRef = useRef<HTMLDivElement>(null);
  const [scale, setScale] = useState(1);

  const { token, user } = useAuth() as authContextProps;

  const {
    addNewSelection,
    setCurrentImageRect,
    currentImageIndex,
    currentImageRect,
    drawingMode,
    // sequenceData,
    fullImageRatioToOg,
    fullScreenWidth,
    selections,
  } = useContext(Context) as ContextProps;

  const handleMouseDown = (event: React.MouseEvent) => {
    event.preventDefault();
    if (imageRef.current) {
      let x = event.clientX;
      let y = event.clientY;

      if (drawingMode === "polygon") {
        setStartCoords({
          x,
          y,
        });
        setEndCoords({ x, y });
      } else if (drawingMode === "lasso") {
        setLassoPoints([]);
        setLassoPoints((prevPoints) => [...prevPoints, { x, y }]);
      }

      if (event.button === 0) {
        setIsDrawing({ active: true, type: "draw" });
      } else if (event.button === 2) {
        setIsDrawing({ active: true, type: "delete" });
      }
    }
  };

  const handleMouseMove = (event: React.MouseEvent) => {
    if (isDrawing.active && imageRef.current) {
      let xCoord = event.clientX;
      let yCoord = event.clientY;
      if (event.clientY < currentImageRect.top) {
        yCoord = Math.ceil(currentImageRect.top);
      } else if (
        event.clientY >
        currentImageRect.top + currentImageRect.height
      ) {
        yCoord = currentImageRect.top + currentImageRect.height;
      }
      if (event.clientX < currentImageRect.left) {
        xCoord = Math.ceil(currentImageRect.left);
      } else if (
        event.clientX >
        currentImageRect.left + currentImageRect.width
      ) {
        xCoord = currentImageRect.left + currentImageRect.width;
      }

      if (drawingMode === "polygon") {
        setEndCoords({
          x: Math.round(xCoord),
          y: Math.round(yCoord),
        });
      } else if (drawingMode === "lasso") {
        setLassoPoints((prevPoints) => {
          const lastPoint = prevPoints[prevPoints.length - 1];
          const distance = Math.sqrt(
            Math.pow(xCoord - lastPoint.x, 2) +
              Math.pow(yCoord - lastPoint.y, 2)
          );
          if (distance >= 5) {
            return [
              ...prevPoints,
              { x: Math.round(xCoord), y: Math.round(yCoord) },
            ];
          }
          return prevPoints;
        });
      }
    }
  };

  const handleMouseUp = (event: React.MouseEvent): void => {
    event.preventDefault();
    let edges: Edge[] = [];
    if (isDrawing.active) {
      if (drawingMode === "polygon") {
        edges = getAllCoordsOfRectangle(
          startCoords,
          endCoords,
          currentImageRect,
          zoomOffScale
        );
      } else if (drawingMode === "lasso") {
        edges = transformLasso(lassoPoints, currentImageRect, zoomOffScale);
      }

      addNewSelection(
        {
          imageId: currentImageIndex,
          selection: {
            selectionId: 0,
            edges: edges,
          },
        },
        isDrawing.type
      );

      setIsDrawing((prev) => ({ type: prev.type, active: false }));
    }
  };

  const handleResize = () => {
    if (imageRef.current) {
      const rect = imageRef.current.getBoundingClientRect();
      setCurrentImageRect({
        top: Math.round(rect.y),
        left: Math.round(rect.x),
        width: rect.width,
        height: rect.height,
      });
      og_scale = 1 / +(1920 / imageRef.current.width).toFixed(3);
      setScale(og_scale);
      setZoomOffScale({
        x: 0,
        y: 0,
        scale: og_scale,
      });
    }
  };

  useEffect(() => {
    if (mainWrapperRef.current) {
      mainWrapperRef.current.focus();
    }

    if (imageRef.current) {
      const rect = imageRef.current.getBoundingClientRect();
      setCurrentImageRect({
        top: Math.round(rect.y),
        left: Math.round(rect.x),
        width: rect.width,
        height: rect.height,
      });
      og_scale = 1 / +(1920 / imageRef.current.width).toFixed(3);

      setScale(og_scale);
      setZoomOffScale({
        x: 0,
        y: 0,
        scale: og_scale,
      });
    }
  }, [fullScreenWidth, setCurrentImageRect, mainWrapperRef]);

  useEffect(() => {
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    sendMarkedSequence(
      adjustToScale(selections, fullImageRatioToOg),
      sequenceData.sequenceName,
      sequenceData.images[0].imageName,
      token,
      user,
      sequenceData
    );
  }, [sequenceData, selections]);

  return (
    <Wrapper
      ref={mainWrapperRef}
      tabIndex={1}
      onContextMenu={(event) => event.preventDefault()}
      onMouseMove={handleMouseMove}
      onMouseLeave={handleMouseUp}
      onMouseUp={handleMouseUp}
    >
      <FullscreenControlPanel
        seq_name={seq_name}
        frame_name={frame_name}
        height={imageRef.current?.height}
        index={index}
        total_frames={total_frames}
        toggle_fullscreen={toggle_screen}
      />

      <TransformWrapper
        disablePadding={true}
        panning={{ disabled: true }}
        ref={wrapperRef}
        maxScale={10}
        minScale={1}
        onZoomStop={(ref, e) => {
          let values = {
            x: 0,
            y: 0,
            scale: og_scale,
          };

          values.scale *= ref.state.scale;
          if (ref.state.positionX <= 0) {
            values.x = ref.state.positionX;
          }
          if (ref.state.positionY <= 0) {
            values.y = ref.state.positionY;
          }

          setZoomOffScale(values);
        }}
      >
        <TransformComponent>
          {sequenceData.images[currentImageIndex] ? (
            <>
              <img
                ref={imageRef}
                className="main-image-fullscreen"
                src={sequenceData.images[currentImageIndex].image}
                alt="select"
              />
              <Canvas
                rect={{
                  top: 0,
                  left: 0,
                  width: currentImageRect.width,
                  height: currentImageRect.height,
                }}
                scale={scale}
                index={currentImageIndex}
                strokeWidth={1.3}
              />
            </>
          ) : (
            <div>Loading</div>
          )}
        </TransformComponent>

        <div
          className="selection-box"
          style={{
            top: currentImageRect.top,
            left: currentImageRect.left,
            width: `${imageRef.current?.width}px`,
            height: `${imageRef.current?.height}px`,
            zIndex: shiftPress ? -1 : 1,
          }}
          onMouseDown={handleMouseDown}

          // onMouseUp={handleMouseUpPoly}
          // onMouseMove={handleMouseMove}
        />
        {/* change to isDrawing.active */}
        {isDrawing.active && (
          <SelectBox
            drawingMode={drawingMode}
            type={isDrawing.type}
            startCoords={startCoords}
            endCoords={endCoords}
            rect={{
              top: currentImageRect.top,
              left: currentImageRect.left,
              imgWidth: imageRef.current?.width,
              imgHeight: imageRef.current?.height,
            }}
            points={lassoPoints}
          />
        )}
      </TransformWrapper>
    </Wrapper>
  );
}

export default Fullscreen;
