import React, {
  useState,
  useRef,
  useEffect,
  useContext,
  useCallback,
} from "react";

import Slider from "../components/Slider";
import Canvas from "../components/Canvas";
import {
  ContextProps,
  Selection,
  Mark,
  Sequence,
} from "../@interfaces/interfaces";
import { Context } from "../context/context";
import ControlPanel from "../components/ControlPanel";
import { useAuth } from "../context/auth";
import { authContextProps } from "../@interfaces/authContext";
import Spinner from "../components/Spinner";
import Fullscreen from "../components/Fullscreen";
import SequenceNav from "../components/SequenceNav";
import { useParams, useLocation, useNavigate } from "react-router-dom";
import {
  fetchSignleLock,
  createLock,
} from "../networking/sequenceControllerNetwork";
import Countdown from "../components/Countdown";
import { Wrapper, ImageWrapper } from "./DashboardPageStyle";
import { frameMinusOne, framePlusOne } from "../label_processing/helper";

let prevPathName = "/";

function DashboardPage() {
  const [isFullscreen, setIsFullscreen] = useState(false);
  const [scale, setScale] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [markList, setMarkList] = useState<Mark[]>([]);
  const [shiftPress, setShiftPress] = useState(false);
  const [folder_type, setFolderType] = useState("");
  const [timer, setTimer] = useState(0); // in seconds
  const { sequence_name, frame } = useParams<{
    sequence_name: string;
    frame: string;
  }>();
  document.title = sequence_name || "Dashboard";

  const imageWrapperRef = useRef<HTMLDivElement>(null);
  const imageRef = useRef<HTMLImageElement>(null);
  const screenRef = useRef<HTMLImageElement>(null);

  const [loadingRect, setLoadingRect] = useState({
    top: 0,
    left: 0,
    width: 0,
    height: 0,
  });

  const {
    sequenceData,
    currentImageIndex,
    currentImageRect,
    fullScreenWidth,
    selections,
    prev,
    fullImageRatioToOg,
    drawingMode,
    setPrev,
    setCurrentImageIndex,
    setCurrentImageRect,
    setFullImageRatioToOg,
    copyPreviousToCurrent,
    setSelections,
    getUnmarkedMarked,
    setImagesAndSelections,
    setDrawingMode,
  } = useContext(Context) as ContextProps;

  const [countdown, setCountdown] = useState<number>(0);
  const [isLocked, setLocked] = useState<boolean>(false);
  const { token } = useAuth() as authContextProps;
  const location = useLocation();
  const navigate = useNavigate();
  const toggleFullscreen = () => setIsFullscreen((prevVal) => !prevVal);

  const handleMainImageClick = (event: React.MouseEvent) => {
    event.preventDefault();

    if (!isFullscreen && event.target instanceof HTMLImageElement) {
      toggleFullscreen();
    }
  };

  const [switchSeqCooldown, setSwitchSeqCooldown] = useState(false);

  const getCurrentRatioOg = () => {
    let ratioOg = 1.0;
    if (screenRef.current) {
      ratioOg = +(
        screenRef.current.naturalWidth / screenRef.current.width
      ).toFixed(3);
      setFullImageRatioToOg(ratioOg);
    }
    return ratioOg;
  };

  const handleKeyClick = (event: React.KeyboardEvent) => {
    if (isFullscreen && sequence_name && frame && isLoading) {
      switch (event.key) {
        case "Shift":
          setShiftPress(true);
          break;
        case "Escape":
          imageWrapperRef.current?.blur();
          toggleFullscreen();
          break;
        case "a":
          setPrev(
            selections.filter(
              (el: Selection) => el.imageId === currentImageIndex
            )
          );

          if (currentImageIndex !== 0) {
            setCurrentImageIndex(currentImageIndex - 1);
          } else if (
            markList.find((el) => el.name === frameMinusOne(frame)) &&
            switchSeqCooldown === false
          ) {
            setSwitchSeqCooldown(true);
            navigate(`/${sequence_name}/${frameMinusOne(frame)}`);
          }
          break;
        case "d":
          setPrev(
            selections.filter(
              (el: Selection) => el.imageId === currentImageIndex
            )
          );

          if (
            currentImageIndex === sequenceData.images.length - 1 &&
            switchSeqCooldown === false
          ) {
            if (markList.find((el) => el.name === framePlusOne(frame))) {
              setSwitchSeqCooldown(true);
              navigate(`/${sequence_name}/${framePlusOne(frame)}`);
            }
          } else if (currentImageIndex < sequenceData.images.length - 1) {
            setCurrentImageIndex(currentImageIndex + 1);
          }
          break;
        case "w":
          copyPreviousToCurrent(prev);
          break;
        case "r":
          if (drawingMode === "polygon") {
            setDrawingMode("lasso");
          } else {
            setDrawingMode("polygon");
          }
      }
    }
  };

  const handleKeyUp = (event: React.KeyboardEvent) => {
    setShiftPress(false);
    event.preventDefault();
  };

  useEffect(() => {
    if (sequence_name && frame) {
      setIsLoading(true);
      if (
        sequenceData.images.length !== 0 &&
        location.pathname === prevPathName
      ) {
        return;
      }

      let diff = 0;
      const new_frame_number = frame.split("image_")[1].split("-frame")[0];
      const prev_frame_number = prevPathName.includes("image_")
        ? prevPathName.split("image_")[1].split("-frame")[0]
        : "";
      let strNumber = parseInt(new_frame_number, 10);
      let strPrevNumber = parseInt(prev_frame_number, 10);
      diff = strNumber - strPrevNumber;

      let indexNum = 0;
      if (isFullscreen && diff === -1) {
        const prev = markList.find((el) => el.name === frame);
        if (prev) indexNum = prev.length - 1;
      }

      setSwitchSeqCooldown(true);

      setSelections([]);

      const ratioOg = getCurrentRatioOg();

      const awaitSetImagesAndSelections = async () => {
        return setImagesAndSelections(
          token,
          sequence_name,
          frame,
          diff,
          prevPathName,
          ratioOg
        );
      };

      awaitSetImagesAndSelections().finally(() => {
        setIsLoading(false);
        setTimeout(() => {
          setSwitchSeqCooldown(false);
        }, 1000);
      });

      prevPathName = location.pathname;
      setCurrentImageIndex(indexNum);
    }
  }, [
    sequenceData,
    markList,
    location.pathname,
    token,
    fullImageRatioToOg,
    sequence_name,
    frame,
  ]);

  const setCurrentRect = useCallback(
    (ref: React.RefObject<HTMLImageElement>) => {
      if (ref.current) {
        const rect = ref.current.getBoundingClientRect();
        setCurrentImageRect({
          top: Math.round(rect.y),
          left: Math.round(rect.x),
          width: rect.width,
          height: rect.height,
        });
      }
    },
    [setCurrentImageRect]
  );

  useEffect(() => {
    if (countdown === 0 && timer > 0) {
      setCountdown(7200 - timer);
    }

    if (countdown <= 0) return;
    const timerPromise = setInterval(() => {
      setCountdown((prevSeconds) => prevSeconds - 1);
    }, 1000);

    return () => clearInterval(timerPromise);
  }, [countdown, timer]);

  useEffect(() => {
    let counter = 1;
    if (
      sequence_name &&
      imageRef.current &&
      folder_type !== "oznacene" &&
      folder_type.length &&
      counter > 0
    ) {
      fetchSignleLock(token, sequence_name)
        .then((res: any) => {
          if (res.success) {
            const currTime = Math.floor(new Date().getTime() / 1000.0);
            const time = new Date(res.time);
            const timeDiff =
              time.getTime() &&
              currTime - Math.floor(time.getTime() / 1000.0) < 7200
                ? currTime - Math.floor(time.getTime() / 1000.0)
                : -1;

            setTimer(timeDiff);
            counter -= 2;
          } else {
            window.alert(
              "You can not start a new sequence while you have another active sequence.\n\n Either finish or delete the active sequence.\n\n Press OK to go back."
            );
            navigate("/");
            counter -= 2;
          }
        })
        .catch((err) => {
          if (
            window.confirm(
              "Do you want to start working on this folder?\n\nYou will have 2 hours to submit your work.\n\nFolder will be locked for other users for the duration of your work.\n\n Press OK to start."
            )
          ) {
            createLock(token, sequence_name).then((res) => {
              setTimeout(() => {
                setLocked(true);
              }, 400);
            });
          } else {
            navigate("/");
            counter -= 2;
          }
        });
    }
  }, [isLocked, sequence_name, imageRef.current, folder_type]);

  const [trigger, setTrigger] = useState(false);

  useEffect(() => {
    // fetching sequence nav data
    if (sequence_name && !isFullscreen) {
      getUnmarkedMarked(token, sequence_name).then((res) => {
        if (res) {
          const result = res.marked;
          setMarkList(result);
          setFolderType(res.folder_type);
          if (!frame) {
            const nonmarked = result.find((el) => el.marked === false);
            let path = "";
            // change the window pathname to the next unmarked sequence
            if (nonmarked) {
              path = `/${sequence_name}/${nonmarked.name}`;
            } else {
              path = `/${sequence_name}/${result[0].name}`;
            }
            navigate(path);
          }
        }
      });
    }
    // setting scale for the big image
    if (!isFullscreen && imageRef.current && fullScreenWidth !== 0) {
      setCurrentRect(imageRef);

      const width = imageRef.current.width;
      setScale(1 / +(fullScreenWidth / width).toFixed(3));
    }
  }, [
    isFullscreen,
    fullScreenWidth,
    // currentImageIndex,
    scale,
    trigger,
    imageRef.current,
  ]);

  useEffect(() => {
    const setCurrentLoadingRect = () => {
      if (imageRef.current) {
        const rect = imageRef.current.getBoundingClientRect();
        setLoadingRect({
          top: Math.round(rect.y),
          left: Math.round(rect.x),
          width: rect.width,
          height: rect.height,
        });
      }
    };
    setCurrentLoadingRect();

    const handleResize = () => {
      if (!isFullscreen && imageRef.current && fullScreenWidth !== 0) {
        setCurrentRect(imageRef);
        setCurrentLoadingRect();
        const width = imageRef.current.width;
        setScale(1 / +(1920 / width).toFixed(3));
      }
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [imageRef.current]);

  return (
    <Wrapper tabIndex={-1} onKeyDown={handleKeyClick} onKeyUp={handleKeyUp}>
      {sequenceData.images.length > 0 && !isFullscreen && (
        <>
          {/* Preview mode */}
          <ImageWrapper ref={imageWrapperRef} tabIndex={1}>
            {!isLoading && (
              <div
                className="spinner"
                style={{
                  top: Math.round(loadingRect.top),
                  left: Math.round(loadingRect.left),
                  width: `${loadingRect.width}px`,
                  height: `${loadingRect.height}px`,
                }}
              >
                <Spinner short={true} />
              </div>
            )}
            <div className={`main-image-box `}>
              <div
                onClick={handleMainImageClick}
                className="main-image-clickbox"
              >
                <span>Click image below to start</span>
                {folder_type !== "oznacene" && countdown > 0 && (
                  <Countdown countdown={countdown} />
                )}
              </div>
              <img
                onClick={handleMainImageClick}
                ref={imageRef}
                className={`main-image ${
                  !isLoading ? "main-image--loading" : ""
                }`}
                src={sequenceData.images[currentImageIndex].image}
                alt="select"
              />

              <Canvas
                rect={currentImageRect}
                index={currentImageIndex}
                scale={scale}
                strokeWidth={2}
              />
            </div>
            <SequenceNav markList={markList} selections={selections} />
            <ControlPanel
              triggerFetch={() => setTrigger(!trigger)}
              markList={markList}
              folder_type={folder_type}
            />
          </ImageWrapper>
          <Slider sliderInfo={sequenceData.images} markList={markList} />
        </>
      )}

      {/* Work mode */}
      {isFullscreen && (
        <Fullscreen
          toggle_screen={toggleFullscreen}
          seq_name={sequence_name}
          frame_name={frame}
          total_frames={markList.find((el) => el.name === frame)?.length || 0}
          index={currentImageIndex}
          shiftPress={shiftPress}
          isLoading={isLoading}
          sequenceData={sequenceData}
        />
      )}
      {!sequenceData.images.length && <Spinner />}
    </Wrapper>
  );
}

export default DashboardPage;
