import React, { useState, useEffect, useContext } from "react";
import { useAuth } from "../context/auth";
import { authContextProps } from "../@interfaces/authContext";
import {
  get_folders,
  checkStaff,
} from "../networking/sequenceControllerNetwork";
import styled from "styled-components";
import FolderLinkItem from "../components/FolderLinkItem";
import { FaRegFolder } from "react-icons/fa";
import { Context } from "../context/context";
import { ContextProps } from "../@interfaces/interfaces";
import { FolderCallProps } from "../@interfaces/other";
import Spinner from "../components/Spinner";

const Wrapper = styled.div`
  height: 100vh;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  background-color: #f8f8f8;
  overflow-y: auto;
  padding: 2em;

  .box--container {
    flex-shrink: 0;
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    background-color: inherit;
    justify-content: center;
   
  }

  @media (max-width: 1300px) {
    .box--container {
      align-items: center;
      gap: 0;
      flex-direction: column;
    }
  }

  .main--box {
    margin-top: 2em;
    border: 1px solid #ccc;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
   
    flex-shrink: 0;
    width: 600px;
    @media (max-width: 600px) {
      width: 100%;
    
    }
  }

  .text--box {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
  }

  .text--main {
    img {
      background-color: inherit;
      margin-right: 0.1em;
    }
    font-size: 2rem;
    font-weight: 600;
    background-color: inherit;
    flex-shrink: 0;
  }

  .span--accent {
    background-color: inherit;
  }

  .text--side {
    font-size: 1.1rem;
    font-weight: bold;
  
    align-self: flex-start;
    @media (max-width: 600px) {
      padding: 0 1em;
    }
  }

  .text--folder {
    background-color: #417690;
    color: #f5dd5d;
    padding: 0.5em;
    margin-bottom: 0;
    width: 100%;
    padding: 0.5em 1em;
  }

  .text--folder--count {
    background-color: inherit;
    font-size: 0.7em;
    margin-left: 1em;
  }

  .text--folder--icon {
    background-color: inherit;
    @media (max-width: 600px) {
      font-size: 0.8em;
    }
  }

  .folders {
    display: flex;
    flex-direction: column;
    width: 100%;
    box-sizing: border-box;
    overflow: auto;
    max-height: calc(100vh - 300px);
  }

  .folder__empty {
    font-weight: 200;
    margin-top: 1em;
  }
`;

interface Folder {
  name: string;
  url: string;
  time: Date;
  user: string;
  status?: string;
}

function HomePage() {
  const { token, user } = useAuth() as authContextProps;
  const [isStaff, setIsStaff] = useState<boolean>(false); // user.user_type === "staff" ? true : false
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const { clearImages, clearSelections } = useContext(Context) as ContextProps;

  const [folders, setFolders] = useState<Folder[]>([]);
  const [foldersMarked, setFoldersMarked] = useState<Folder[]>([]);
  const [foldersRejected, setFoldersRejected] = useState<Folder[]>([]);

  document.title = "Stribor";
  useEffect(() => {
    setIsLoading(true);
    clearImages();
    clearSelections();
    get_folders(token).then((res) => {
      if (res.success) {
        setFolders(
          res.data.map((el: FolderCallProps) => {
            return {
              name: el.name,
              url: el.url,
              time: new Date(el.time),
              user: el.user,
            };
          })
        );

        if (res.dataStaff) {
          setFoldersMarked(
            res.dataStaff.map((el: FolderCallProps) => {
              return {
                name: el.name,
                url: el.url,
                time: new Date(el.time),
                user: el.user,
                status: el.status,
              };
            })
          );
        }

        if (res.dataRejected) {
          setFoldersRejected(
            res.dataRejected.map((el: FolderCallProps) => {
              return {
                name: el.name,
                url: el.url,
                time: new Date(el.time),
                user: el.user,
                status: el.status,
              };
            })
          );
        }
      }
      checkStaff(token).then((res) => {
        if (res.data.is_staff) {
          setIsStaff(true);
        }
      });
      setIsLoading(false);
    });
  }, []);

  return (
    <Wrapper>
      {isLoading && <Spinner />}
      {!isLoading && (
        <>
          <div className="text--main">
            Welcome <span className="span--accent">{user.username}!</span>
          </div>
          {/* Main labeling box */}
          <div className="box--container">
            <div className="main--box">
              <div className="text--box">
                <div className="text--side text--folder">
                  <FaRegFolder className="text--folder--icon" />{" "}
                  <span className="text--folder--icon">/home/Neoznacene</span>
                  <span className="text--folder--count">{folders.length} items left</span>
                </div>
              </div>
              {folders.length === 0 && (
                <div className="folder__empty">No sequences to label</div>
              )}
              <div className="folders">
                {folders.sort((a, b) => a.name.localeCompare(b.name)).map((folder, i) => {
                  const currTime = Math.floor(new Date().getTime() / 1000.0);
                  const timeDiff =
                    folder.time.getTime() &&
                    currTime - Math.floor(folder.time.getTime() / 1000.0) < 7200
                      ? currTime - Math.floor(folder.time.getTime() / 1000.0)
                      : -1;
                  return (
                    <FolderLinkItem
                      key={i}
                      isAdmin={isStaff}
                      folder_group="neoznacene"
                      folder={folder.name}
                      lock_timer={timeDiff}
                      userThatMadeSeq={folder.user}
                    />
                  );
                })}
              </div>
            </div>

            {/* Error labeling box */}
            <div className="main--box">
              <div className="text--box">
                
                <div className="text--side text--folder">
                  <FaRegFolder className="text--folder--icon" />{" "}
                  <span className="text--folder--icon">/home/Popraviti</span>
                </div>
              </div>
              {foldersRejected.length === 0 && (
                <div className="folder__empty">No sequences to fix</div>
              )}
              <div className="folders">
                {foldersRejected.sort((a, b) => a.name.localeCompare(b.name)).map((folder, i) => {
                  return (
                    <FolderLinkItem
                      key={i}
                      folder_group="rejected"
                      folder={folder.name}
                      reviewed={folder.status === "marked" ? true : false}
                      userThatMadeSeq={folder.user}
                    />
                  );
                })}
              </div>
            </div>

            {/* Admin labeling box */}
            {isStaff && (
              <div className="main--box">
                <div className="text--box">

                  <div className="text--side text--folder">
                    <FaRegFolder className="text--folder--icon" />{" "}
                    <span className="text--folder--icon">/home/Oznacene</span>
                  </div>
                </div>
                {foldersMarked.length === 0 && (
                  <div className="folder__empty">No sequences to review</div>
                )}
                <div className="folders">
                  {foldersMarked.sort((a, b) => a.name.localeCompare(b.name)).map((folder, i) => {
                    return (
                      <FolderLinkItem
                        key={i}
                        folder_group="oznacene"
                        folder={folder.name}
                        reviewed={folder.status === "marked" ? true : false}
                        userThatMadeSeq={folder.user}
                      />
                    );
                  })}
                </div>
              </div>
            )}
          </div>
        </>
      )}
    </Wrapper>
  );
}

export default HomePage;
