export const framePlusOne = (frame: string) => {
  const new_frame_number = frame.split("image_")[1].split("-frame")[0];
  let strNumber = parseInt(new_frame_number, 10);
  let frameNumber = Math.min(strNumber + 1, 99999);
  let stringFrame = `image_${frameNumber.toString().padStart(5, "0")}-frame-00`;
  return stringFrame;
};
export const frameMinusOne = (frame: string) => {
  const new_frame_number = frame.split("image_")[1].split("-frame")[0];
  let strNumber = parseInt(new_frame_number, 10);
  let frameNumber = Math.max(strNumber - 1, 0);
  let stringFrame = `image_${frameNumber.toString().padStart(5, "0")}-frame-00`;
  return stringFrame;
};
