import styled from "styled-components";
import { useRef, useEffect } from "react";
interface Props {
  drawingMode: string;
  type: string;
  startCoords: { x: number; y: number };
  endCoords: { x: number; y: number };
  rect: {
    top: number;
    left: number;
    imgWidth: number | undefined;
    imgHeight: number | undefined;
  };
  points: { x: number; y: number }[];
}

const Polygon = styled.div<any>`
  position: absolute;
  border: 2px
    ${(props) => (props.type === "draw" ? "dashed red" : "dashed blue")};
  background-color: transparent;
`;

function SelectBox({
  startCoords,
  endCoords,
  type,
  rect,
  drawingMode,
  points,
}: Props) {
  const contextRef = useRef<HTMLCanvasElement>(null);

  const Render = () => {
    const ctx = contextRef.current?.getContext("2d");
    if (ctx && rect.imgWidth && rect.imgHeight) {
      ctx.clearRect(0, 0, rect.imgWidth, rect.imgHeight);
      ctx.save();
      RenderSelection(points);

      ctx.restore();
    }
  };

  // function ClearSelection() {
  //   for (let index = 0; index < seats.length; index++) {
  //     let seat = seats[index];
  //     seat.selected = false;
  //   }
  // }

  function RenderSelection(points: { x: number; y: number }[]) {
    const ctx = contextRef.current?.getContext("2d");
    if (ctx) {
      if (points.length <= 1) {
        return;
      }
      ctx.setLineDash([5, 3]);
      ctx.fillStyle = "rgba(0,0,0,0.2)";
      ctx.lineWidth = 1;
      if (type === "draw") {
        ctx.strokeStyle = "red";
      } else {
        ctx.strokeStyle = "blue";
      }

      ctx.beginPath();
      for (let index = 0; index < points.length; index++) {
        let point = points[index];
        if (index == 0) {
          ctx.moveTo(point.x - rect.left, point.y - rect.top);
        } else {
          ctx.lineTo(point.x - rect.left, point.y - rect.top);
        }
      }

      ctx.lineTo(points[0].x - rect.left, points[0].y - rect.top);
      ctx.fill();
      ctx.stroke();
      ctx.closePath();
    }
  }

  useEffect(() => {
    if (contextRef.current) {
      Render();
    }
  }, [contextRef, points]);

  if (drawingMode === "polygon")
    return (
      <Polygon
        type={type}
        style={{
          left: Math.min(startCoords.x, endCoords.x),
          top: Math.min(startCoords.y, endCoords.y),
          width: Math.abs(endCoords.x - startCoords.x),
          height: Math.abs(endCoords.y - startCoords.y),
        }}
      />
    );

  return (
    <canvas
      style={{
        zIndex: 10000,
        position: "absolute",
        top: rect.top,
        left: rect.left,
        backgroundColor: "transparent",
      }}
      ref={contextRef}
      width={rect.imgWidth}
      height={rect.imgHeight}
    ></canvas>
  );
}

export default SelectBox;
