import styled from "styled-components";

export const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: row;
  align-items: stretch;
  padding: 2em 4em;
  flex-shrink: 0;

  @media (max-width: 1000px) {
    padding: 1em 2em;
  }

  @media (max-width: 700px) {
    padding: 0.5em;
    gap: 0.5em;
    flex-direction: column;
  }
  gap: 1em;
  background-color: #f8f8f8;
  max-height: calc(100vh - 35px);

  .container-fullscreen {
    position: absolute;
    display: none;
    justify-content: center;
    overflow: hidden;
    z-index: -100;
    align-items: center;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: black;
  }
`;

export const ImageWrapper = styled.div`
  flex-shrink: 0;
  box-sizing: border-box;
  display: flex;
  padding: 1em;
  flex-shrink: 1;
  max-width: 500px;
  min-height: fit-content;
  flex-direction: column;
  justify-content: flex-start;
  border: 1px solid #ccc;

  @media (max-width: 700px) {
    height: 700px;
  }

  .main-image-box {
    flex-shrink: 0;

    &:hover {
      // add black tint absolute
    }
  }

  .main-image-clickbox {
    width: 100%;
    background-color: rgb(65, 118, 144);
    color: #f5dd5d;
    display: flex;

    padding: 0.5em;
    display: flex;
    align-items: center;
    justify-content: space-between;
    span {
      background-color: inherit;
      display: flex;
      justify-content: center;
      gap: 0.1em;
    }

    img {
      background-color: inherit;
      width: 20px;
      text-align: center;
    }
  }

  .main-image {
    vertical-align: top;
    width: 100%;
    object-fit: contain;

    &:hover {
      cursor: pointer;
    }
    &--loading {
      filter: brightness(50%);
    }
  }
  .spinner {
    position: absolute;
    z-index: 100;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: transparent;
  }
`;
